export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const CUSTOM_PRODUCT_IMAGE = "CUSTOM_PRODUCT_IMAGE";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

// fetch products
export const fetchProducts = products => {
  return dispatch => {
    dispatch(fetchProductsSuccess(products));
  };
};

export const addCustomProductImage = customImage => {
  return dispatch => {
    dispatch({
      type: CUSTOM_PRODUCT_IMAGE,
      payload: customImage
    });
  };
};